export default {
  data() {
    return {
      listener: null,
      viewport_width: false,
    };
  },
  methods: {
    compute_viewport_width() {
      if (process.client) {
        this.viewport_width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      }
    },
  },
  destroyed() {
    if (process.client) {
      window.removeEventListener("resize", this.listener);
    }
  },
  mounted() {
    if (process.client) {
      this.listener = window.addEventListener("resize", this.compute_viewport_width, true);
    }
  },
  beforeMount() {
    if (process.client) {
      this.compute_viewport_width();
    }
  },
};
